










import BaseButtonIcon from '@/components/base/button/BaseButtonIcon.vue';
import Vue, { VueConstructor } from 'vue';
import { IActionButtonEvent } from '@/typings/interface/IActionButtonEvent';

/**
   * Used to initialize actions via an icon only. The `:focus` state is left as browser standard.
   */
export default (Vue as VueConstructor).extend({
  name: 'BaseGridActionListItem',

  components: {
    BaseButtonIcon,
  },

  props: {
    /**
       * Generates scss modifier 'button--<type>' and emits 'clicked-<type>'
       */
    actionName: {
      default: '',
      type: String,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    eventId: {
      default: '',
      type: [
        String,
        Number,
      ],
    },

    eventName: {
      default: '',
      type: String,
    },

    icon: {
      default: '',
      type: String,
    },

    contextMenuTrigger: {
      default: false,
      type: Boolean,
    },
  },

  computed: {
    buttonClass(): string {
      let className = 'button';

      if (this.actionName) {
        className = `${className} button--${this.actionName}`;
      }

      if (this.contextMenuTrigger) {
        className = `${className} context-menu-trigger`;
      }

      return className;
    },
  },

  methods: {
    onAction(event: Event): void {
      const payload: IActionButtonEvent = {
        event: event,
        eventName: this.eventName,
        ID: this.eventId,
        contextMenuTrigger: this.contextMenuTrigger,
      };

      this.$emit('click:action', payload);
    },
  },
});
