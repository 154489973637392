



































import BaseButtonText from '@/components/base/button/BaseButtonText.vue';
import BaseInputField from '@/components/base/BaseInputField.vue';
import LayoutHeader from '@/layouts/LayoutHeader.vue';
import Vue, { VueConstructor } from 'vue';

export default (Vue as VueConstructor).extend({
  name: 'BaseGridHeader',

  components: {
    BaseButtonText,
    BaseInputField,
    LayoutHeader,
  },

  props: {
    headerCustomClass: {
      type: String,
      default: '',
    },

    heading: {
      type: String,
      default: '',
    },

    filterable: {
      type: Boolean,
      default: false,
    },

    visible: {
      type: Boolean,
      default: true,
    },

    visibleActions: {
      type: Boolean,
      default: true,
    },

    visibleAddButton: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      searchField: '',
    };
  },

  methods: {
    onFilterTextBoxChanged(value: string): void {
      this.$emit('filter', value);
    },
  },
});
