



















import BaseButtonText from '@/components/base/button/BaseButtonText.vue';
import Vue, { VueConstructor } from 'vue';

export default (Vue as VueConstructor).extend({
  name: 'BaseGridFooter',

  components: {
    BaseButtonText,
  },

  props: {
    actionName: {
      type: String,
      default: 'Add',
    },

    variant: {
      type: String,
      default: 'table',
    },

    visible: {
      type: Boolean,
      default: true,
    },
  },
});
