
/*
  NOTE: when working on this file, browser-sync's hot-reload functionality will not work correctly!
  after the hot-reload, Vue tries to directly render this abstract component which of course leads
  to errors
 */
import BaseGridActionList from './BaseGridActionList.vue';
import Vue, { VueConstructor } from 'vue';
import { find as _find, findIndex as _findIndex, isArray as _isArray } from 'lodash';
import { AgGridVue } from '@ag-grid-community/vue';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { EventBusEvent } from '@/utils/eventBus';
import { GRID_TYPE } from '@/typings/type/TGridType';
import { IActionButtonEvent } from '@/typings/interface/IActionButtonEvent.ts';
import {
  IBaseContextMenuAction,
  IBaseContextMenuContextData,
  IBaseContextMenuVisibility,
} from '@/typings/interface/IBaseContextMenuEventPayloads';
import { POSITION } from '@/utils/positions';
import { size } from '@/assets/styles/base/variables/size/size';
import { utIsEmpty } from '@/utils/empty';
import '@ag-grid-community/client-side-row-model';

export default (Vue as VueConstructor).extend({
  name: 'BaseGrid',

  components: {
    AgGridVue,
  },

  props: {
    actionList: {
      type: Array,
      default: function() {
        return [];
      },
    },

    actionName: {
      type: String,
      default: 'Add',
    },

    // ag-grid api method call
    apiMethodCall: {
      type: Object,
      default: null,
    },

    columnDefinition: {
      type: Array,
      default: function() {
        return [];
      },
    },

    contextName: {
      type: String,
      default: '',
    },

    customRowHeight: {
      type: Number,
      default: size.tappableSquare,
    },

    customSortColumn: {
      type: Number,
      default: 0,
    },

    draggable: {
      type: Boolean,
      default: false,
    },

    filterable: {
      type: Boolean,
      default: false,
    },

    gridHeight: {
      type: String,
      default: 'auto',
    },

    gridType: {
      type: String,
      default: GRID_TYPE.TABLE,
    },

    hideActionColumn: {
      type: Boolean,
      default: false,
    },

    noRowsToShowMessage: {
      type: String,
      default: '',
    },

    orderSorting: {
      type: Array,
      default: function() {
        return [
          'desc',
          'asc',
          null,
        ];
      },
    },

    rowClassRules: {
      type: Object,
      default: null,
    },

    rowData: {
      type: Array,
      default: function() {
        return [];
      },
    },

    rowDataLength: {
      type: Number,
      default: 0,
    },

    sortable: {
      type: Boolean,
      default: false,
    },

    visibleActions: {
      type: Boolean,
      default: true,
    },

    visibleAddButton: {
      type: Boolean,
      default: true,
    },

    visibleFooter: {
      type: Boolean,
      default: true,
    },

    visibleHeader: {
      type: Boolean,
      default: true,
    },

  },

  data() {
    return {
      columnDefs: null,

      contextMenu: {},

      disableOnClickListener: false,

      gridApi: null,

      gridClassAppendix: '',

      gridOptions: {
        accentedSort: true,

        animateRows: true,

        // autoSizePadding set to 0.01 is a workaround because 0 gets ignored
        autoSizePadding: 6,

        context: {
          componentParent: this,
        },

        defaultColDef: {
          resizable: false,
          sortable: false,
          suppressMovable: true,
        },

        domLayout: this.gridHeight === 'auto' ? 'autoHeight' : 'normal',

        getRowNodeId: (data) => {
          return data.actions;
        },

        headerHeight: size.tappableSquare,

        rowClass: this.draggable ? 'row--is-draggable' : '',

        rowClassRules: this.rowClassRules ? this.rowClassRules : null,

        rowHeight: this.customRowHeight,

        suppressCellSelection: true,

        // TODO: check if suppressColumnVirtualisation affects performance once the BiG DATA has arisen
        suppressColumnVirtualisation: true,

        suppressNoRowsOverlay: true,

        suppressRowTransform: true,

        sortingOrder: this.orderSorting,
      },

      hideHeaderRow: (this.gridType === GRID_TYPE.LIST),

      isBaseGridDraggable: this.draggable,

      isBaseGridList: (this.gridType === GRID_TYPE.LIST),

      isBaseGridTable: (this.gridType === GRID_TYPE.TABLE),

      showOldTable: false,

      modules: AllCommunityModules,
    };
  },

  computed: {
    contextMenuTriggerExists(): boolean {
      return (this.contextMenuTriggerIndex > -1);
    },

    contextMenuTriggerIndex(): number {
      return (
        _findIndex(
          this.actionList,
          {
            contextMenuTrigger: true,
          },
        )
      );
    },

    gridStyle() {
      if (this.gridHeight === 'auto') {
        return '';
      } else {
        return `height: ${this.gridHeight}; width: calc(100% + 60px);`;
      }
    },

    headerHeight() {
      let headerHeight = size.tappableSquare;

      if (!this.draggable && (this.hideHeaderRow || utIsEmpty(this.rowData))) {
        headerHeight = 0;
      }

      if (this.isBaseGridList) {
        headerHeight = 0;
      }

      return headerHeight;
    },
  },

  watch: {
    async apiMethodCall(callData) {
      switch (callData.function) {
        case 'setColumnDefs':
          this.gridApi.setColumnDefs(callData.payload);

          break;

        case 'setRowData':
          this.gridApi.setRowData(callData.payload);

          break;

        case 'updateRowData':
          this.gridApi.updateRowData({
            [callData.command]: [
              callData.payload,
            ],
          });

          break;

        case 'fitColumns':

          this.customColumnFitting();

          break;

        default:
          this.$logger.warn('unknown function in apiMethodCall prop (BaseGrid.vue)');

          break;
      }
    },

    headerHeight() {
      this.gridOptions.headerHeight = this.headerHeight;

      this.gridApi.refreshHeader();
    },
  },

  methods: {
    agRowRemoveHoveredState(): void {
      const agAllRows = document.querySelectorAll('.ag-row');

      agAllRows.forEach(
        row => {
          row.classList.remove('keep-hover-style');

          row.classList.remove('ag-row-hover');
        },
      );
    },

    calcCombinedWidthOfAllColumns(columnApi): number {
      let tempWidth = 0;

      this.columnDefs.forEach(
        columnDefinition => {
          tempWidth += columnApi.getColumn(columnDefinition.field).getActualWidth();
        },
      );

      return tempWidth;
    },

    customColumnFitting(): void {
      if (this.gridApi && this.columnApi) {
        let gridWidthWithoutActionMenu: number = 0;

        if (process.env.NODE_ENV !== 'test') {
          this.gridApi.sizeColumnsToFit();
        }

        const widthAfterSizeColsToFit = this.calcCombinedWidthOfAllColumns(this.columnApi);

        this.columnApi.autoSizeAllColumns();

        const widthAfterAutoSize = this.calcCombinedWidthOfAllColumns(this.columnApi);

        if (widthAfterSizeColsToFit > widthAfterAutoSize) {
          // gridcolumns are sized to fit
          if (process.env.NODE_ENV !== 'test') {
            this.gridApi.sizeColumnsToFit();
          }

          this.gridClassAppendix = 'sized-to-fit';

          gridWidthWithoutActionMenu = widthAfterSizeColsToFit - size.gridActionMenu;
        } else {
          // gridColumns are autosized
          this.gridClassAppendix = 'autosized';

          gridWidthWithoutActionMenu = widthAfterAutoSize - size.gridActionMenu;
        }
      }
    },

    getContextMenuActions(Id: string): Array<Object> {
      const data = _find(
        this.rowData,
        data => data.actions === Id,
      );

      const actionList = [];

      this.actionList[this.contextMenuTriggerIndex].childActionList.map(action => {
        if (action.event !== 'bon' && action.event !== 'cancel' && action.event !== 'reversal') {
          actionList.push(action);

          return;
        }

        if (
          data?.delivery !== this.$tc('common.property.yes') &&
          action.event === 'bon'
        ) {
          actionList.push(action);

          return;
        }

        if (
          action.event === 'cancel' &&
          utIsEmpty(data?.canceled) &&
          data?.reversal === this.$t('common.property.no') &&
          !utIsEmpty(data?.datePaid)
        ) {
          actionList.push(action);

          return;
        }

        if (
          action.event === 'reversal' &&
          !utIsEmpty(data?.canceled) &&
          data?.reversal === this.$t('common.property.no')
        ) {
          actionList.push(action);
        }
      });

      return this.contextMenuTriggerExists
        ? actionList : [];
    },

    nodeSelection(selection: any): void {
      this.$emit('select-tree-node', selection);
    },

    offClickListener(event): void {
      if (event === 'simulate' || !event.target.classList.contains('context-menu-trigger')) {
        this.agRowRemoveHoveredState();

        if (this.contextMenuTriggerExists) {
          this.$bus.$emit(EventBusEvent.CONTEXTMENU.setIsVisible, false);
        }
      }
    },

    onActionClick(payload: IActionButtonEvent): void {
      if (payload.contextMenuTrigger) {
        const contextData: IBaseContextMenuContextData = {
          actions: this.getContextMenuActions(payload.ID),
          ID: payload.ID,
          name: this.contextName,
          position: POSITION.LEFT,
          trigger: payload.event.target,
        };

        this.$bus.$emit(EventBusEvent.CONTEXTMENU.update, contextData);
      } else {
        this.$emit(payload.eventName, payload.ID);
      }
    },

    onContextMenuActionClicked(action: IBaseContextMenuAction): void {
      // only emit if in according context

      if (this.contextName === action.contextName) {
        // simulate an offClick so BaseContextMenu is hidden
        this.offClickListener('simulate');

        this.$emit(action.name, action.ID);
      }
    },

    onContextMenuVisibilityChange(visibility: IBaseContextMenuVisibility): void {
      if (visibility.contextName === this.contextName) {
        // reset hovered row style states
        this.agRowRemoveHoveredState();

        if (visibility.visible) {
          const agActiveRows = document.querySelectorAll(`.ag-row[row-id='${visibility.ID}']`);

          agActiveRows.forEach(
            activeRow => {
              activeRow.classList.add('keep-hover-style');
            },
          );
        }

        this.contextMenu.visible = visibility.visible;
      }
    },

    onFilterTextBoxChanged(value: string) {
      this.gridOptions.api.setQuickFilter(value);
    },

    onGridReady(params): void {
      this.$emit('grid-ready', params);

      this.gridApi = params.api;

      this.columnApi = params.columnApi;

      // we want the columns to fill up the whole grid's width when they would take less space
      // but the grid should also be horizontally scrollable when there are more columns
      // this functionality isn't officially provided by ag grid yet:
      // https://github.com/ag-grid/ag-grid/issues/1772
      // https://github.com/ag-grid/ag-grid/issues/1188
      window.setTimeout(() => {
        if (this.gridApi?.gridPanel?.eBodyViewport?.clientWidth > 0) {
          this.customColumnFitting();
        }
      }, 16);

      if (this.contextMenuTriggerExists) {
        window.addEventListener('click', this.offClickListener);
      }

      if (!this.disableOnClickListener) {
        this.$el.querySelector('.ag-center-cols-container')
          .addEventListener('click', this.onRowClick);
      }
    },

    onGridSizeChanged(event): void {
      // TODO: keeping this native event-method here so we know it exits when we need it
    },

    onRowClick(event): void {
      const rowID = event.target.getAttribute('row-id');

      if (rowID) {
        this.$emit('click:row', rowID);
      }
    },

    onRowClickedNative(event) {
    // TODO: maybe refactor all grids to use this native row click event?
    },
  },

  beforeMount(): void {
    const actionColumnWidth = (2 * size.iconSquare) + 4 + 8;

    if (this.columnDefinition.length > 0) {
      // remove border-right from last cells in column
      if (!_isArray(this.columnDefinition[this.columnDefinition.length - 1].cellClass)) {
        this.columnDefinition[this.columnDefinition.length - 1].cellClass = [
          this.columnDefinition[this.columnDefinition.length - 1].cellClass,
        ];
      }

      this.columnDefinition[this.columnDefinition.length - 1].cellClass = [
        ...this.columnDefinition[this.columnDefinition.length - 1].cellClass,
        'border-right--none',
      ];

      if (!_isArray(this.columnDefinition[this.columnDefinition.length - 1].headerClass)) {
        this.columnDefinition[this.columnDefinition.length - 1].headerClass = [
          this.columnDefinition[this.columnDefinition.length - 1].headerClass,
        ];
      }

      this.columnDefinition[this.columnDefinition.length - 1].headerClass = [
        ...this.columnDefinition[this.columnDefinition.length - 1].headerClass,
        'border-right--none',
      ];
    }

    // sort ascending by 1st column - unless it's a BaseGridTreeList or a BaseGridDraggable (table or list)
    if (!this.isBaseGridTreeList && !this.isBaseGridDraggable) {
      this.columnDefinition[0].sort = 'asc';

      this.columnDefinition.forEach(
        columnDefinition => {
          columnDefinition.sortingOrder = [
            'asc',
            'desc',
          ];
        },
      );
    }

    if (this.sortable) {
      this.columnDefinition[0].sort = null;

      this.columnDefinition.forEach(
        columnDefinition => {
          columnDefinition.sortingOrder = [
            'asc',
            'desc',
          ];
        },
      );
    }

    let actionsRenderer = null;
    let actionsRendererFramework = BaseGridActionList;

    if (this.hideActionColumn) {
      actionsRenderer = () => '';

      actionsRendererFramework = null;
    }

    this.columnDefs = [
      {
        cellRenderer: actionsRenderer,

        cellRendererFramework: actionsRendererFramework,

        cellStyle: {
          'padding': 0,
          'justify-content': 'flex-end',
        },

        field: 'actions',

        filterParams: {
          actionList: this.actionList,
        },

        headerName: '',

        maxWidth: actionColumnWidth,

        minWidth: actionColumnWidth,

        pinned: 'left',

        suppressNavigable: true,

        width: actionColumnWidth,
      },

      ...this.columnDefinition,
    ];

    this.gridOptions = {
      ...this.gridOptions,

      headerHeight: this.headerHeight,
    };

    if (this.contextMenuTriggerExists) {
      this.$bus.$on(EventBusEvent.CONTEXTMENU.visibilityChanged, this.onContextMenuVisibilityChange);

      this.$bus.$on(EventBusEvent.CONTEXTMENU.actionClicked, this.onContextMenuActionClicked);

      this.$bus.$on(EventBusEvent.MODAL.opened, this.agRowRemoveHoveredState);
    }
  },

  beforeDestroy(): void {
    if (this.$el.querySelector('.ag-center-cols-container') !== null &&
      !this.disableOnClickListener) {
      this.$el.querySelector('.ag-center-cols-container')
        .removeEventListener('click', this.onRowClick);
    }

    if (this.contextMenuTriggerExists) {
      window.removeEventListener('click', this.offClickListener);

      this.$bus.$off(EventBusEvent.CONTEXTMENU.visibilityChanged, this.onContextMenuVisibilityChange);

      this.$bus.$off(EventBusEvent.CONTEXTMENU.actionClicked, this.onContextMenuActionClicked);

      this.$bus.$off(EventBusEvent.MODAL.opened, this.agRowRemoveHoveredState);
    }
  },
});
