





























import BaseGridActionListItem from '@/components/base/grid/BaseGridActionListItem.vue';
import BaseTooltip from '@/components/base/BaseTooltip.vue';
import Vue, { VueConstructor } from 'vue';
import {
  isBoolean as _isBoolean,
  isObject as _isObject,
  isUndefined as _isUndefined,
} from 'lodash';
import { EventBusEvent } from '@/utils/eventBus';
import { i18n } from '@/locales/i18n';
import { IActionButtonEvent } from '@/typings/interface/IActionButtonEvent.ts';
import { IBaseContextMenuVisibility } from '@/typings/interface/IBaseContextMenuEventPayloads';
import { utIsEmpty } from '@/utils/empty';
import CONTEXTMENU = EventBusEvent.CONTEXTMENU;

export default (Vue as VueConstructor).extend({
  name: 'BaseGridActionList',

  components: {
    BaseGridActionListItem,
    BaseTooltip,
  },

  data() {
    return {
      actionList: [],
      data: null,
      disabled: false,
      ID: '' as string,
      forceTooltipHiding: false,
    };
  },

  computed: {
    actionsArePresent(): boolean {
      return !_isUndefined(this.actionList) && !utIsEmpty(this.actionList);
    },
  },

  methods: {
    isVisible(action): boolean {
      if (
        action.event !== 'bon' &&
        action.event !== 'reversal' &&
        action.event !== 'cancel'
      ) {
        return true;
      }

      if (
        this.data?.delivery === this.$tc('common.property.yes') &&
        action.event === 'bon'
      ) {
        return false;
      }

      if (
        action.event === 'reversal' &&
        !utIsEmpty(this.data?.canceled)
      ) {
        return false;
      }

      if (
        action.event === 'cancel' &&
        (
          !utIsEmpty(this.data?.datePaid) ||
          this.data?.canceled === this.$t('invoice.property.canceled')
        )
      ) {
        return false;
      }

      return true;
    },

    onActionClick(payload: IActionButtonEvent): void {
      if (!this.disabled) {
        this.params.context.componentParent.onActionClick(payload);
      }
    },

    setVisibility(visibilityValue: boolean | IBaseContextMenuVisibility): void {
      if (_isObject(visibilityValue)) {
        const { visible } = visibilityValue;

        this.forceTooltipHiding = visible;
      } else if (_isBoolean(visibilityValue)) {
        this.forceTooltipHiding = visibilityValue;
      } else {
        this.$logger.error('visibilityValue has unsupported data type!');
      }
    },
  },

  created(): void {
    this.$bus.$on(CONTEXTMENU.visibilityChanged, this.setVisibility);

    this.$bus.$on(CONTEXTMENU.setIsVisible, this.setVisibility);
  },

  destroyed(): void {
    this.$bus.$off(CONTEXTMENU.visibilityChanged, this.setVisibility);

    this.$bus.$off(CONTEXTMENU.setIsVisible, this.setVisibility);
  },

  mounted(): void {
    this.actionList = this.params.colDef.filterParams.actionList;

    this.disabled = (this.params.data.disabled) ? this.params.data.disabled : false;

    this.ID = this.params.value;

    this.data = this.params.data;
  },
});
